/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Bus may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Students associated with this Bus will be modified.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Bus was successfully deleted.' }
}

export async function cascadeDeleteBus(busId, swalCallback) {
    try {
        /** Update Student records associated to this Bus **/
        //todo - Remove busStudentsId from student records, bus is now handled by studentEvent
       /* const students = await listStudents(busId, swalCallback)
        await batchProcessWithRetry(students, async (student) => {
            await API.graphql(graphqlOperation(updateStudentMutation, { input: { id: student.id, busStudentsId: null }} ));
        }, { title: 'Updating Students', callback: swalCallback })*/

        /** Update StudentEvent records associated to this Bus **/
        const studentEvents = await listStudentEventsByBus(busId, swalCallback)
        await batchProcessWithRetry(studentEvents, async (studentEvent) => {
            await API.graphql(graphqlOperation(updateStudentEventMutation, { input: { id: studentEvent.id, busId: null }} ));
        }, { title: 'Updating Student Events', callback: swalCallback })

        /** Delete Bus **/
        await deleteBus(busId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteBus(busId, swalCallback) {
    const title = 'Deleting Bus'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteBusMutation, { input: { id: busId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listStudents(busId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { busStudentsId: { eq: busId } }}
            const response = await API.graphql(graphqlOperation(listStudentsQuery, input));
            items.push(...response.data.listStudents.items)
            nextToken = response.data.listStudents.nextToken;
            swalCallback(new SwalData('Loading Students', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

async function listStudentEventsByBus(busId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { busId: busId, limit: 250, nextToken: nextToken }
            const response = await API.graphql(graphqlOperation(listStudentEventsByBusQuery, input));
            items.push(...response.data.listStudentEventsByBus.items)
            nextToken = response.data.listStudentEventsByBus.nextToken;
            swalCallback(new SwalData('Loading Student Events', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const listStudentsQuery = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                busStudentsId
            }
            nextToken
        }
    }
`;

const listStudentEventsByBusQuery = /* GraphQL */ `
    query ListStudentEventsByBus(
        $busId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentEventsByBus(
            busId: $busId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;

const updateStudentMutation = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
        }
    }
`;

const updateStudentEventMutation = /* GraphQL */ `
    mutation UpdateStudentEvent(
        $input: UpdateStudentEventInput!
        $condition: ModelStudentEventConditionInput
    ) {
        updateStudentEvent(input: $input, condition: $condition) {
            id
        }
    }
`;

const deleteBusMutation = /* GraphQL */ `
    mutation DeleteBus(
        $input: DeleteBusInput!
        $condition: ModelBusConditionInput
    ) {
        deleteBus(input: $input, condition: $condition) {
            id
        }
    }
`;
