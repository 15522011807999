import { render, staticRenderFns } from "./Buses.vue?vue&type=template&id=232e3797&"
import script from "./Buses.vue?vue&type=script&lang=js&"
export * from "./Buses.vue?vue&type=script&lang=js&"
import style0 from "./Buses.vue?vue&type=style&index=0&id=232e3797&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports